import { DataGrid } from '@material-ui/data-grid'
import { useEffect, useMemo, useState } from 'react'
import { postIndustry } from '../data'
import { Container, LinearProgress } from '@material-ui/core'

function ReprocessOutput({ reprocessList }) {
    console.log(reprocessList)
    const columns = useMemo(
        () => [
            {
                field: 'typeID',
                headerName: '',
                width: 50,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
                            alt={params.row.item}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'typeName',
                headerName: 'Item',
                width: 500,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'qty',
                headerName: 'Quantity',
                width: 250,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'sell',
                headerName: 'Sell',
                width: 250,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'buy',
                headerName: 'Buy',
                width: 250,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
        ],
        []
    )

    return (
        <div style={{ width: '100%' }}>
            <h4>Info</h4>
            <ul style={{ textAlign: 'left' }}>
                <li>Reprocessing cost: {reprocessList.fee ? reprocessList.fee.toLocaleString() : null}</li>
                <li>Corp tax: {reprocessList.tax ? reprocessList.tax.toLocaleString() : null}</li>
                <li>
                    Contract to <b>Les bobos batisseurs</b> for :{' '}
                    {reprocessList.buyout ? reprocessList.buyout.toLocaleString() : null}
                </li>
            </ul>
            <DataGrid
                columns={columns}
                rows={reprocessList.data ?? []}
                rowHeight={25}
                autoHeight
                getRowId={(row) => row.typeID ?? row.item}
            />
        </div>
    )
}

export default ReprocessOutput
