import { useEffect, useMemo, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { postIndustry } from '../data'
import { Button, Container, FormControl, InputLabel, LinearProgress, NativeSelect } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

function MarketWatch() {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [config, setConfig] = useState({
        market: '3L',
    })
    const fetchData = () => {
        Promise.all([postIndustry(['market_watch'], { config: config })])
            .then((results) => {
                setData(results[0].data)
                setLoaded(true)
                setError(null)
            })
            .catch((error) => {
                setError(error)
            })
    }

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: '',
                width: 50,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
                            alt={params.row.itemName}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'name',
                headerName: 'Item',
                width: 200,
            },
            {
                field: 'stock',
                headerName: 'Volume objective',
                width: 200,
            },
            {
                field: 'volume',
                headerName: 'Total market',
                width: 200,
            },
            {
                field: 'volume_ok',
                headerName: 'OK market',
                width: 200,
            },
            {
                field: 'level',
                headerName: 'Price objective',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'avg',
                headerName: 'Market average',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'objective',
                headerName: 'Level',
                width: 200,
                renderCell: (params) =>
                    params.value < 100 ? (
                        <span style={{ color: 'red' }}>{params.value.toLocaleString()}</span>
                    ) : (
                        <span>{params.value.toLocaleString()}</span>
                    ),
            },
        ],
        []
    )

    useEffect(() => {
        fetchData()
    }, [])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress />

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>Market Watcher</title>
            </Helmet>
            <FormControl>
                <InputLabel>Target Market</InputLabel>
                <NativeSelect
                    inputProps={{
                        name: 'market',
                    }}
                    onChange={(e) =>
                        setConfig((x) => ({ ...x, market: e.target.value }))
                    }
                >
                    <option value={'3L'}>3L</option>
                </NativeSelect>
            </FormControl>
            <FormControl>
                <Button variant="contained" onClick={fetchData}>
                    Apply
                </Button>
            </FormControl>
            <ul style={{ textAlign: 'left' }}>
                <li>Market: {config.market}</li>
            </ul>
            <DataGrid columns={columns} rows={data} rowHeight={25} autoHeight />
        </div>
    )
}

export default MarketWatch
