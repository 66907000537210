import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    FormControl,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Paper,
    Tab,
    Tabs,
} from '@material-ui/core'
import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import { postIndustry } from '../data'
import { Alert, AlertTitle } from '@material-ui/lab'
import Moment from 'react-moment'
import { DataGrid } from '@material-ui/data-grid'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props
    const classes = useStyles()

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Paper className={classes.paper}>{children}</Paper>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function LpStoreExtraItems({ extra }) {
    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: '',
                width: 50,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
                            alt={params.row.itemName}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'name',
                headerName: 'Item',
                width: 300,
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                width: 150,
            },
            {
                field: 'price',
                headerName: 'Price per unit',
                width: 300,
            },
        ],
        []
    )
    if (extra.length) {
        return <DataGrid columns={columns} rows={extra} rowHeight={25} autoHeight />
    }
    return <br />
}

function LpStoreItem({ data }) {
    const classes = useStyles()
    return (
        <Grid item xs>
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar alt={'icon'} src={`https://images.evetech.net/corporations/${data.corp_id}/logo`} />
                    }
                    title={
                        <h5>
                            {data.corp} in {data.system}
                        </h5>
                    }
                />
                <CardContent>
                    <li>ISK cost {data.isk_cost}</li>
                    <li>LP cost {data.lp_cost}</li>
                    <li>Distance from jita: {data.distance} jumps</li>
                    {data.extra_cost ? <li> Extra cost: {data.extra_cost}</li> : ' '}
                    {data.extra ? <LpStoreExtraItems extra={data.extra} /> : ' '}
                </CardContent>
            </Card>
        </Grid>
    )
}

function LpStore() {
    const classes = useStyles()
    const [item, setItem] = useState('')
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [searching, setSearching] = useState(null)
    const handleChange = (event) => {
        setItem(event.target.value)
    }

    const search = () => {
        setSearching(true)
        Promise.all([postIndustry(['lp_store'], { item: item })])
            .then((results) => {
                setData(results[0].data)
                setSearching(false)
                setError(null)
            })
            .catch((error) => {
                setSearching(false)
                setError(error)
            })
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <title>LP Store information</title>
            </Helmet>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Paper className={classes.paper}>
                        <FormControl>
                            <InputLabel htmlFor="item">Item name</InputLabel>
                            <Input
                                id="item"
                                onBlur={handleChange}
                                onKeyUp={(e) => {
                                    if (e.code === 'Enter') handleChange(e)
                                }}
                            />
                        </FormControl>
                    </Paper>
                    {searching ? (
                        <LinearProgress />
                    ) : (
                        <Button variant="contained" onClick={search}>
                            Search
                        </Button>
                    )}{' '}
                </Grid>
            </Grid>
            {error ? (
                <Container>
                    <h3>Error</h3>
                    <p>{error.message}</p>
                </Container>
            ) : (
                ' '
            )}
            {[...Array(Math.ceil(data?.length) ?? 0)].map((x, idx) => (
                <Grid container spacing={3} key={idx}>
                    {data[idx] ? <LpStoreItem data={data[idx]} /> : <Grid item xs />}
                </Grid>
            ))}
        </div>
    )
}

export default LpStore
