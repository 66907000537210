import { DataGrid } from '@material-ui/data-grid'
import { useEffect, useMemo, useState } from 'react'
import { postIndustry } from '../data'
import { Container, LinearProgress } from '@material-ui/core'

function ReprocessList({ reprocessList }) {
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [loaded, setLoaded] = useState(false)

  const columns = useMemo(
    () => [
      {
        field: 'typeID',
        headerName: '',
        width: 50,
        renderCell: (params) =>
          params.row.invalid ? null : (
            <img
              src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
              alt={params.row.item}
              width={25}
              height={25}
            />
          ),
      },
      {
        field: 'item',
        headerName: 'Item',
        width: 500,
        renderCell: (params) =>
          params.row.invalid || !params.row.can_reprocess ? (
            <span style={{ color: 'red' }}>{params.value}</span>
          ) : (
            <span>{params.value}</span>
          ),
      },
      {
        field: 'quantity',
        headerName: 'Quantity',
        width: 250,
        valueFormatter: (params) => params.value.toLocaleString(),
      },
    ],
  )

  useEffect(() => {
    const fetchData = () => {
      setData([])
      Promise.all([postIndustry(['prepare_reprocessing'], reprocessList)])
        .then((results) => {
          setData(results[0].data)
          setLoaded(true)
          setError(null)
        })
        .catch((error) => {
          setError(error)
        })
    }
    fetchData()
  }, [reprocessList])

  if (error) {
    return (
      <Container>
        <h3>Error</h3>
        <p>{error.response.data ?? error.message}</p>
      </Container>
    )
  }

  return (
    <div style={{ width: '100%' }}>
      {loaded ? null : <LinearProgress />}
      <DataGrid
        columns={columns}
        rows={data}
        rowHeight={25}
        autoHeight
        getRowId={(row) => row.typeID ?? row.item}
      />
    </div>
  )
}

export default ReprocessList
