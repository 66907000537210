import {
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    makeStyles,
    NativeSelect,
    Paper,
    Slider,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core'
import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getItemsQuantity } from './inventoryparser'
import { postIndustry } from '../data'
import IndustryBuild from './IndustryBuild'
import { Helmet } from 'react-helmet-async'
import { DataGrid } from '@material-ui/data-grid'
import ReprocessList from './ReprocessList'
import ReprocessOutput from './ReprocessOutput'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props
    const classes = useStyles()

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Paper className={classes.paper}>{children}</Paper>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function Reprocessing() {
    const classes = useStyles()
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [reprocessList, setReprocessList] = useState(null)
    const [building, setBuilding] = useState(null)
    const [config, setConfig] = useState({
        fee: 5,
        tax: 15,
    })
    const [tab, setTab] = useState(0)
    const handleInputChange = (event) => {
        setReprocessList(getItemsQuantity(event.target.value))
        setTab(0)
    }

    const changeTab = (event, newValue) => {
        setTab(newValue)
    }

    const reprocess = () => {
        const final_config = {
            fee: config.fee,
            tax: config.tax,
            facility: config.facility,
        }
        setBuilding(true)
        Promise.all([postIndustry(['process_reprocess'], { config: final_config, data: reprocessList })])
            .then((results) => {
                setData(results[0].data)
                setBuilding(false)
                setError(null)
                setTab(1)
            })
            .catch((error) => {
                setError(error)
            })
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <title>Reprocessing</title>
            </Helmet>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Paper className={classes.paper}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="input">Input</InputLabel>
                            <Input
                                id="input"
                                onBlur={handleInputChange}
                                onKeyUp={(e) => {
                                    if (e.code === 'Enter') handleInputChange(e)
                                }}
                                multiline
                                rows={25}
                                fullWidth
                            />
                        </FormControl>
                    </Paper>
                    <Paper className={classes.paper}>
                        <FormControl fullWidth>
                            <Typography id={'fee'}>Reprocessing fee</Typography>
                            <Slider
                                aria-labelledby="fee"
                                defaultValue={5}
                                step={1}
                                min={0}
                                max={10}
                                valueLabelDisplay={'on'}
                                onChange={(e, v) => setConfig((x) => ({ ...x, fee: v }))}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography id={'tax'}>Mining tax</Typography>
                            <Slider
                                aria-labelledby="tax"
                                defaultValue={15}
                                step={5}
                                min={0}
                                max={50}
                                valueLabelDisplay={'on'}
                                onChange={(e, v) => setConfig((x) => ({ ...x, tax: v }))}
                            />
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    {building ? (
                        <LinearProgress />
                    ) : (
                        <Button variant="contained" onClick={reprocess}>
                            Reprocess !
                        </Button>
                    )}
                    <Tabs value={tab} onChange={changeTab} aria-label="simple tabs example">
                        <Tab label="ItemList" id={'tab-0'} aria-controls={'tabpanel-0'} />
                        <Tab label="Output" id={'tab-1'} aria-controls={'tabpanel-1'} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                      {reprocessList !== null ? <ReprocessList reprocessList={reprocessList} /> : null}
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        {data !== null ? <ReprocessOutput reprocessList={data} /> : null}
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    )
}

export default Reprocessing
