import { useEffect, useMemo, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { postIndustry } from '../data'
import { Button, Container, FormControl, InputLabel, LinearProgress, NativeSelect } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

function HullPrices({ build_type }) {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [config, setConfig] = useState({
        rsystem: '1DQ1-A',
        fsystem: '1DQ1-A',
    })
    const fetchData = () => {
        Promise.all([postIndustry([build_type], { config: config })])
            .then((results) => {
                setData(results[0].data)
                setLoaded(true)
                setError(null)
            })
            .catch((error) => {
                setError(error)
            })
    }

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: '',
                width: 50,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
                            alt={params.row.itemName}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'name',
                headerName: 'Item',
                width: 150,
            },
            {
                field: 'group',
                headerName: 'Group',
                width: 150,
            },
            {
                field: 'cost',
                headerName: 'Cost',
                width: 150,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'local_sell',
                headerName: 'UALX sell',
                width: 150,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'local_volume',
                headerName: 'Weekly',
                width: 150,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'local_markup',
                headerName: 'Markup',
                width: 150,
                renderCell: (params) =>
                    params.value < 0 ? (
                        <span style={{ color: 'red' }}>{params.value.toLocaleString()}</span>
                    ) : (
                        <span>{params.value.toLocaleString()}</span>
                    ),
            },
            {
                field: 'local_margin',
                headerName: 'Margin',
                width: 150,
                renderCell: (params) =>
                    params.value < 0 ? (
                        <span style={{ color: 'red' }}>{params.value.toLocaleString()}%</span>
                    ) : (
                        <span>{params.value.toLocaleString()}%</span>
                    ),
            },
            {
                field: 'jita_sell',
                headerName: 'Jita sell',
                width: 150,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'jita_markup',
                headerName: 'Markup',
                width: 150,
                renderCell: (params) =>
                    params.value < 0 ? (
                        <span style={{ color: 'red' }}>{params.value.toLocaleString()}</span>
                    ) : (
                        <span>{params.value.toLocaleString()}</span>
                    ),
            },
            {
                field: 'jita_margin',
                headerName: 'Margin',
                width: 150,
                renderCell: (params) =>
                    params.value < 0 ? (
                        <span style={{ color: 'red' }}>{params.value.toLocaleString()}%</span>
                    ) : (
                        <span>{params.value.toLocaleString()}%</span>
                    ),
            },
            {
                field: 'jita_volume',
                headerName: 'Weekly',
                width: 150,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
        ],
        []
    )

    useEffect(() => {
        fetchData()
    }, [])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress />

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>T2 hulls prices</title>
            </Helmet>
            <FormControl>
                <InputLabel>Reacting System</InputLabel>
                <NativeSelect
                    inputProps={{
                        name: 'rsystem',
                    }}
                    onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                        setConfig((x) => ({ ...x, rsystem: e.target.value }))
                    }
                >
                    <option value={'A4B-V5'}>A4B-V5</option>
                    <option value={'B-S347'}>B-S347</option>
                    <option value={'B9E-H6'}>B9E-H6</option>
                    <option value={'Jita'}>Jita</option>
                    <option value={'JK-Q77'}>JK-Q77</option>
                    <option value={'SPBS-6'}>SPBS-6</option>
                    <option value={'UALX-3'}>UALX-3</option>
                    <option value={'Y19P-1'}>Y19P-1</option>
                    <option value={'Y2-QUV'}>Y2-QUV</option>
                </NativeSelect>
            </FormControl>
            <FormControl>
                <InputLabel>Manufacturing System</InputLabel>
              <NativeSelect
                inputProps={{
                  name: 'fsystem',
                }}
                onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                  setConfig((x) => ({ ...x, fsystem: e.target.value }))
                }
              >
                <option value={'94FR-S'}>94FR-S</option>
                <option value={'A4B-V5'}>A4B-V5</option>
                <option value={'B-S347'}>B-S347</option>
                <option value={'B9E-H6'}>B9E-H6</option>
                <option value={'D-BAMJ'}>D-BAMJ</option>
                <option value={'E1F-LK'}>E1F-LK</option>
                <option value={'F76-8Q'}>F76-8Q</option>
                <option value={'GM-0K7'}>GM-0K7</option>
                <option value={'H-RXNZ'}>H-RXNZ</option>
                <option value={'I-NGI8'}>I-NGI8</option>
                <option value={'JK-Q77'}>JK-Q77</option>
                <option value={'KW-OAM'}>KW-OAM</option>
                <option value={'LN-56V'}>LN-56V</option>
                <option value={'O3Z5-G'}>O3Z5-G</option>
                <option value={'O7-7UX'}>O7-7UX</option>
                <option value={'PH-NFR'}>PH-NFR</option>
                <option value={'Q-HJ97'}>Q-HJ97</option>
                <option value={'R-ZUOL'}>R-ZUOL</option>
                <option value={'RF6T-8'}>RF6T-8</option>
                <option value={'RHE7-W'}>RHE7-W</option>
                <option value={'SPB6-S'}>SPBS-6</option>
                <option value={'UALX-3'}>UALX-3</option>
                <option value={'W-FHWJ'}>W-FHWJ</option>
                <option value={'X-6WC7'}>X-6WC7</option>
                <option value={'Y19P-1'}>Y19P-1</option>
                <option value={'Y2-QUV'}>Y2-QUV</option>
                <option value={'Z4-QLD'}>Z4-QLD</option>
                <option value={'ZJA-6U'}>ZJA-6U</option>
              </NativeSelect>
            </FormControl>
          <FormControl>
            <Button variant="contained" onClick={fetchData}>
              Apply
            </Button>
          </FormControl>
          <ul style={{ textAlign: 'left' }}>
            <li>Manufacturing system: {config.fsystem}</li>
            <li>Reaction system: {config.rsystem}</li>
          </ul>
          <ul style={{ textAlign: 'center' }}>
            Invention cost for hulls is a bit inflated. Based on one job with parity decryptor.
            <br />
            Module invention without decryptor, so rigs invention cost might be wrong.
          </ul>
          <DataGrid columns={columns} rows={data} rowHeight={25} autoHeight />
        </div>
    )
}

export default HullPrices
