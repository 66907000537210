import { useEffect, useState } from 'react'
import { getIndustryInfo } from '../data'
import { Container, LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

function Planets() {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const fetchData = () => {
            Promise.all([getIndustryInfo(['planets', '10000060'])])
                .then((results) => {
                    setData(results[0].data)
                    setLoaded(true)
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress />

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>Planets</title>
            </Helmet>
            {Object.values(data).map((x, idx) => (
                <div key={idx}>
                    <img
                        src={`https://images.evetech.net/types/${x.typeID}/icon?size=32`}
                        alt={x.planetType}
                        width={25}
                        height={25}
                    />
                    {x.itemName} ({x.planetType.replace('Planet (', '').replace(')', '')})
                    {x.materials.length === 0 ? (
                        'No Data'
                    ) : (
                        <ul>
                            {x.materials.map((m, idx2) => (
                                <li key={idx2}>
                                    <img
                                        src={`https://images.evetech.net/types/${m.material_id}/icon?size=32`}
                                        alt={m.material_name}
                                        width={25}
                                        height={25}
                                    />
                                    &nbsp;{m.material_name} : {Math.round(m.amount * 100)}%
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            ))}
        </div>
    )
}

export default Planets
