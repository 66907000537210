import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    LinearProgress,
    makeStyles,
    NativeSelect,
    Paper,
    Slider,
    Switch,
    Tab,
    Tabs,
    Typography,
} from '@material-ui/core'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { getItemsQuantity } from '../lib/fitparser'
import { postIndustry } from '../data'
import IndustryList from './IndustryList'
import IndustryBuild from './IndustryBuild'
import { Helmet } from 'react-helmet-async'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props
    const classes = useStyles()

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Paper className={classes.paper}>{children}</Paper>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function IndustryHome() {
    const classes = useStyles()
    const [building, setBuilding] = useState(false)
    const [buildList, setBuildList] = useState(null)
    const [buildResult, setBuildResult] = useState(null)
    const [config, setConfig] = useState({
        t1_me: 10,
        t2_me: 2,
        composite: false,
        polymer: false,
        fuel_blocks: true,
        seed: false,
        skip_invention: true,
        force_jita: false,
        force_1dq: false,
        job_multiplier: 1,
        force_compress: false,
        repr_rate: 0.9063,
        fsystem: 'T-IPZB',
        rsystem: '1DQ1-A',
        ffacility: '1DQ1-A - T2 Ships & Comps, T1 Equip',
        rfacility: '1DQ1-A - GEZ T2 Reprocessing T2 Reactions',
    })
    const [tab, setTab] = useState(0)
    const handleEFTChange = (event) => {
        setBuildList(getItemsQuantity(event.target.value))
        setTab(0)
        //postIndustry(['build'], buildList)
    }
    // const handleConfigChange = (event) => {
    //     try {
    //         setConfig(JSON.parse(event.target.value))
    //         setTab(0)
    //     } catch (e) {
    //         console.error(e)
    //         setConfig({})
    //     }
    //     //postIndustry(['build'], buildList)
    // }

    const changeTab = (event, newValue) => {
        setTab(newValue)
    }

    const build = () => {
        setBuilding(true)
        setBuildResult([])
        let raw_groups = []
        if (config.composite) {
            raw_groups.push('Composite')
        }
        if (config.polymer) {
            raw_groups.push('Hybrid Polymers')
        }
        if (config.molecular) {
            raw_groups.push('Molecular-Forged Materials')
        }
        if (config.construction_comp) {
            raw_groups.push('Construction Components')
        }
        if (config.cap_construction_comp) {
            raw_groups.push('Capital Construction Components')
        }
        if (config.adv_cap_construction_comp) {
            raw_groups.push('Advanced Capital Construction Components')
        }
        if (config.fuel_blocks) {
            raw_groups.push('Fuel Block')
        }
        const final_config = {
            t1_me: 1 - config.t1_me / 100,
            t2_me: 1 - config.t2_me / 100,
            raw_groups: raw_groups,
            mineral_opt: config.mineral_opt,
            force_compress: config.force_compress,
            force_jita: config.force_jita,
            force_1dq: config.force_1dq,
            seed: config.seed,
            skip_invention: config.skip_invention,
            fsystem: config.fsystem,
            rsystem: config.rsystem,
            repr_rate: config.repr_rate,
            job_multiplier: config.job_multiplier,
            facility: { manufacturing: config.ffacility, reaction: config.rfacility },
        }
        Promise.all([postIndustry(['build'], { todo: buildList, config: final_config })])
            .then((results) => {
                setBuildResult(results[0].data)
                setBuilding(false)
                setTab(1)
            })
            .catch((error) => {
                setBuilding(false)
            })
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <title>Industry</title>
            </Helmet>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Paper className={classes.paper}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="eft">EFT Block</InputLabel>
                            <Input
                                id="eft"
                                onBlur={handleEFTChange}
                                onKeyUp={(e) => {
                                    if (e.code === 'Enter') handleEFTChange(e)
                                }}
                                multiline
                                rows={20}
                                fullWidth
                            />
                        </FormControl>
                    </Paper>
                    <Paper className={classes.paper}>
                        <FormControl fullWidth>
                            <Typography id={'t1_me'}>T1 ME</Typography>
                            <Slider
                                aria-labelledby="t1_me"
                                defaultValue={10}
                                step={1}
                                min={0}
                                max={10}
                                valueLabelDisplay={'on'}
                                onChange={(e, v) => setConfig((x) => ({ ...x, t1_me: v }))}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography id={'t2_me'}>T2 ME</Typography>
                            <Slider
                                aria-labelledby="t2_me"
                                defaultValue={2}
                                step={1}
                                min={0}
                                max={10}
                                valueLabelDisplay={'on'}
                                onChange={(e, v) => setConfig((x) => ({ ...x, t2_me: v }))}
                            />
                        </FormControl>
                        <FormControl fullWidth>
                            <Typography id={'job_multiplier'}>Job multiplier</Typography>
                            <Slider
                                aria-labelledby="job_multiplier"
                                defaultValue={1}
                                step={1}
                                min={1}
                                max={500}
                                valueLabelDisplay={'on'}
                                onChange={(e, v) => setConfig((x) => ({ ...x, job_multiplier: v }))}
                            />
                        </FormControl>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.polymer}
                                        name={'polymer'}
                                        onChange={(e) => setConfig((x) => ({ ...x, polymer: e.target.checked }))}
                                    />
                                }
                                label={'Skip hybrid polymers'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.composite}
                                        name={'composite'}
                                        onChange={(e) => setConfig((x) => ({ ...x, composite: e.target.checked }))}
                                    />
                                }
                                label={'Skip composite'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.fuel_blocks}
                                        name={'fuel_blocks'}
                                        onChange={(e) => setConfig((x) => ({ ...x, fuel_blocks: e.target.checked }))}
                                    />
                                }
                                label={'Skip fuel blocks'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.molecular}
                                        name={'molecular'}
                                        onChange={(e) => setConfig((x) => ({ ...x, molecular: e.target.checked }))}
                                    />
                                }
                                label={'Skip molecular forged materials'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.construction_comp}
                                        name={'construction_comp'}
                                        onChange={(e) =>
                                            setConfig((x) => ({ ...x, construction_comp: e.target.checked }))
                                        }
                                    />
                                }
                                label={'Skip construction components'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.cap_construction_comp}
                                        name={'cap_construction_comp'}
                                        onChange={(e) =>
                                            setConfig((x) => ({ ...x, cap_construction_comp: e.target.checked }))
                                        }
                                    />
                                }
                                label={'Skip capital construction components'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.adv_cap_construction_comp}
                                        name={'adv_cap_construction_comp'}
                                        onChange={(e) =>
                                            setConfig((x) => ({ ...x, adv_cap_construction_comp: e.target.checked }))
                                        }
                                    />
                                }
                                label={'Skip advanced capital construction components'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.skip_invention}
                                        name={'skip_invention'}
                                        onChange={(e) => setConfig((x) => ({ ...x, skip_invention: e.target.checked }))}
                                    />
                                }
                                label={'Skip invention'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.mineral_opt}
                                        name={'mineral_opt'}
                                        onChange={(e) => setConfig((x) => ({ ...x, mineral_opt: e.target.checked }))}
                                    />
                                }
                                label={'Use compressed ore'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.force_compress}
                                        name={'force_compress'}
                                        onChange={(e) => setConfig((x) => ({ ...x, force_compress: e.target.checked }))}
                                    />
                                }
                                label={'Force compression'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.force_jita}
                                        name={'force_jita'}
                                        onChange={(e) => setConfig((x) => ({ ...x, force_jita: e.target.checked }))}
                                    />
                                }
                                label={'Use only jita market'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.force_1dq}
                                        name={'force_1dq'}
                                        onChange={(e) => setConfig((x) => ({ ...x, force_1dq: e.target.checked }))}
                                    />
                                }
                                label={'Use only 1DQ market'}
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={config.seed}
                                        name={'seed'}
                                        onChange={(e) => setConfig((x) => ({ ...x, seed: e.target.checked }))}
                                    />
                                }
                                label={'No manufacturing'}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel>Building System</InputLabel>
                            <NativeSelect
                                inputProps={{
                                    name: 'fsystem',
                                }}
                                onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                                    setConfig((x) => ({ ...x, fsystem: e.target.value }))
                                }
                            >
                                <option value={'94FR-S'}>94FR-S</option>
                                <option value={'A4B-V5'}>A4B-V5</option>
                                <option value={'B-S347'}>B-S347</option>
                                <option value={'B9E-H6'}>B9E-H6</option>
                                <option value={'D-BAMJ'}>D-BAMJ</option>
                                <option value={'E1F-LK'}>E1F-LK</option>
                                <option value={'F76-8Q'}>F76-8Q</option>
                                <option value={'GM-0K7'}>GM-0K7</option>
                                <option value={'H-RXNZ'}>H-RXNZ</option>
                                <option value={'I-NGI8'}>I-NGI8</option>
                                <option value={'JK-Q77'}>JK-Q77</option>
                                <option value={'KW-OAM'}>KW-OAM</option>
                                <option value={'LN-56V'}>LN-56V</option>
                                <option value={'O3Z5-G'}>O3Z5-G</option>
                                <option value={'O7-7UX'}>O7-7UX</option>
                                <option value={'PH-NFR'}>PH-NFR</option>
                                <option value={'Q-HJ97'}>Q-HJ97</option>
                                <option value={'R-ZUOL'}>R-ZUOL</option>
                                <option value={'RF6T-8'}>RF6T-8</option>
                                <option value={'RHE7-W'}>RHE7-W</option>
                                <option value={'SPBS-6'}>SPBS-6</option>
                                <option value={'UALX-3'}>UALX-3</option>
                                <option value={'W-FHWJ'}>W-FHWJ</option>
                                <option value={'X-6WC7'}>X-6WC7</option>
                                <option value={'Y19P-1'}>Y19P-1</option>
                                <option value={'Y2-QUV'}>Y2-QUV</option>
                                <option value={'Z4-QLD'}>Z4-QLD</option>
                                <option value={'ZJA-6U'}>ZJA-6U</option>
                            </NativeSelect>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Manufacturing Facility</InputLabel>
                            <NativeSelect
                                inputProps={{
                                    name: 'ffacility',
                                }}
                                onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                                    setConfig((x) => ({ ...x, ffacility: e.target.value }))
                                }
                            >
                                <option value={'1DQ1-A - T2 Ships & Comps, T1 Equip'}>T2 Sotiyo</option>
                                <option value={'GEZ Grid'}>GEZ Grid</option>
                            </NativeSelect>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Reacting System</InputLabel>
                            <NativeSelect
                                inputProps={{
                                    name: 'rsystem',
                                }}
                                onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                                    setConfig((x) => ({ ...x, rsystem: e.target.value }))
                                }
                            >
                                <option value={'A4B-V5'}>A4B-V5</option>
                                <option value={'B-S347'}>B-S347</option>
                                <option value={'B9E-H6'}>B9E-H6</option>
                                <option value={'Jita'}>Jita</option>
                                <option value={'JK-Q77'}>JK-Q77</option>
                                <option value={'SPBS-6'}>SPBS-6</option>
                                <option value={'UALX-3'}>UALX-3</option>
                                <option value={'Y19P-1'}>Y19P-1</option>
                                <option value={'Y2-QUV'}>Y2-QUV</option>
                            </NativeSelect>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Reacting Facility</InputLabel>
                            <NativeSelect
                                inputProps={{
                                    name: 'rfacility',
                                }}
                                onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                                    setConfig((x) => ({ ...x, rfacility: e.target.value }))
                                }
                            >
                                <option value={'1DQ1-A - GEZ T2 Reprocessing T2 Reactions'}>T2 Reactor</option>
                                <option value={'GEZ T1 Tatara'}>T1 Reactor</option>
                            </NativeSelect>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Reprocessing Facility</InputLabel>
                            <NativeSelect
                                inputProps={{
                                    name: 'repr_rate',
                                }}
                                onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                                    setConfig((x) => ({ ...x, repr_rate: e.target.value }))
                                }
                            >
                                <option value={0.9063}>T2 Tatara</option>
                                <option value={0.8721}>T1 Tatara</option>
                                <option value={0.8762}>T2 Athanor</option>
                                <option value={0.8432}>T1 Athanor</option>
                            </NativeSelect>
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    {building ? (
                        <LinearProgress />
                    ) : (
                        <Button variant="contained" onClick={build}>
                            Build!
                        </Button>
                    )}
                    <Tabs value={tab} onChange={changeTab} aria-label="simple tabs example">
                        <Tab label="ItemList" id={'tab-0'} aria-controls={'tabpanel-0'} />
                        <Tab label="Build" id={'tab-1'} aria-controls={'tabpanel-1'} />
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        {buildList !== null ? <IndustryList buildList={buildList} industryConfig={config} /> : null}
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        {buildResult !== null ? <IndustryBuild data={buildResult} industryConfig={config} /> : null}
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    )
}

export default IndustryHome
