import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link as RouterLink } from 'react-router-dom'
import { Avatar, Card, CardHeader, Link } from '@material-ui/core'
import { useRecoilValue } from 'recoil'
import { getIdentity } from '../data'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    card: {
        padding: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

function AppsHome() {
    const classes = useStyles()
    const loginData = useRecoilValue(getIdentity)

    return (
        <div className={classes.root}>
            {[...Array(Math.ceil(loginData.apps?.length / 3) ?? 0)].map((x, idx) => (
                <Grid container spacing={3} key={idx}>
                    {loginData.apps[idx * 3] ? (
                        <Grid item xs>
                            <Card className={classes.card}>
                                <Link component={RouterLink} to={loginData.apps[idx * 3].link} color="inherit">
                                    <CardHeader
                                        avatar={<Avatar alt={'icon'} src={loginData.apps[idx * 3].image} />}
                                        title={loginData.apps[idx * 3].title}
                                        subheader={loginData.apps[idx * 3].subtitle}
                                    />
                                </Link>
                            </Card>
                        </Grid>
                    ) : (
                        <Grid item xs />
                    )}
                    {loginData.apps[idx * 3 + 1] ? (
                        <Grid item xs>
                            <Card className={classes.card}>
                                <Link component={RouterLink} to={loginData.apps[idx * 3 + 1].link} color="inherit">
                                    <CardHeader
                                        avatar={<Avatar alt={'icon'} src={loginData.apps[idx * 3 + 1].image} />}
                                        title={loginData.apps[idx * 3 + 1].title}
                                        subheader={loginData.apps[idx * 3 + 1].subtitle}
                                    />
                                </Link>
                            </Card>
                        </Grid>
                    ) : (
                        <Grid item xs />
                    )}
                    {loginData.apps[idx * 3 + 2] ? (
                        <Grid item xs>
                            <Card className={classes.card}>
                                <Link component={RouterLink} to={loginData.apps[idx * 3 + 2].link} color="inherit">
                                    <CardHeader
                                        avatar={<Avatar alt={'icon'} src={loginData.apps[idx * 3 + 2].image} />}
                                        title={loginData.apps[idx * 3 + 2].title}
                                        subheader={loginData.apps[idx * 3 + 2].subtitle}
                                    />
                                </Link>
                            </Card>
                        </Grid>
                    ) : (
                        <Grid item xs />
                    )}
                </Grid>
            ))}
        </div>
    )
}

export default AppsHome
