import axios from 'axios'
import { atomFamily, selector } from 'recoil'

export function getSSOInfo(parts, escape = true) {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}sso/${parts.map((x) => (escape ? encodeURIComponent(x) : x)).join('/')}`,
        { withCredentials: true }
    )
}

export function postSSO(parts, data, escape = true) {
    return axios.post(
        `${process.env.REACT_APP_BASE_URL}sso/${parts.map((x) => (escape ? encodeURIComponent(x) : x)).join('/')}`,
        data,
        { withCredentials: true }
    )
}

export function getIndustryInfo(parts, escape = true) {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/industry/${parts
            .map((x) => (escape ? encodeURIComponent(x) : x))
            .join('/')}`,
        { withCredentials: true }
    )
}

export function getFracksInfo(parts, escape = true) {
    return axios.get(
        `${process.env.REACT_APP_BASE_URL}api/v1/fracks/${parts
            .map((x) => (escape ? encodeURIComponent(x) : x))
            .join('/')}`,
        { withCredentials: true }
    )
}

export function postIndustry(parts, data, escape = true) {
    return axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/industry/${parts
            .map((x) => (escape ? encodeURIComponent(x) : x))
            .join('/')}`,
        data,
        { withCredentials: true }
    )
}

export const requestIDState = atomFamily({
    key: 'requestIDState',
    default: 0,
})

export const getIdentity = selector({
    key: 'getIdentity',
    get: async ({ get }) => {
        get(requestIDState('sso'))
        return await getSSOInfo(['user'])
            .then((results) => results.data)
            .catch((error) => null)
    },
})
