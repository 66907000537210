import { has, reject } from 'lodash'

const SHIP_REGEX = /\[([\w\s]+),\s*(.+)\]/i
const CARGO_ITEM_REGEX = /(x[0-9]+)$/
const MODULE_WITH_CHARGE_REGEX = /^([\w\s-]+), ([\w\s]+)/i
const EMPTY_SLOT_REGEX = /\[empty [a-z]+ slot\]/i

export function isEmptySlot(eftLine) {
    return EMPTY_SLOT_REGEX.test(eftLine)
}

export function isCargoItem(eftLine) {
    return CARGO_ITEM_REGEX.test(eftLine)
}

export function parseCargoItem(eftLine) {
    const matches = CARGO_ITEM_REGEX.exec(eftLine)

    return matches
        ? {
              name: eftLine.replace(CARGO_ITEM_REGEX, '').trim(),
              quantity: parseInt(matches[0].replace(/^x/, '').trim(), 10),
          }
        : null
}

export function parseShipType(eftText) {
    const matches = SHIP_REGEX.exec(eftText)
    return matches ? matches[1] : null
}

export function getModulesAndCargoList(eftText) {
    const list = eftText
        .trim()
        .substring(eftText.indexOf(']\n') + 1)
        .split(/\n/)
    return reject(list, (line) => line.trim() === '')
}

export function getItemsQuantity(eftText) {
    const rawItems = []

    getModulesAndCargoList(eftText).forEach((line) => {
        line = line.replace('/OFFLINE', '').trim()

        if (isEmptySlot(line)) {
            // Do nothing
        } else if (isCargoItem(line)) {
            rawItems.push(parseCargoItem(line))
        } else if (MODULE_WITH_CHARGE_REGEX.test(line)) {
            const matches = line.split(',')
            if (matches) {
                rawItems.push({ name: matches[0].trim(), quantity: 1 })
                rawItems.push({ name: matches[1].trim(), quantity: 1 })
            }
        } else {
            rawItems.push({ name: line.trim(), quantity: 1 })
        }
    })

    const fitItems = {}

    const ship = parseShipType(eftText)
    if (ship !== null) fitItems[ship] = 1

    rawItems.forEach((item) => {
        if (item) {
            if (!has(fitItems, item.name)) {
                fitItems[item.name] = item.quantity
            } else {
                fitItems[item.name] += item.quantity
            }
        }
    })

    const output = []
    for (const key of Object.keys(fitItems)) {
        output.push({ item: key, quantity: fitItems[key] })
    }

    return output
}
