import { useEffect, useMemo, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { postIndustry } from '../data'
import { Button, Container, FormControl, InputLabel, LinearProgress, NativeSelect } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

function ReactionsProcess() {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [config, setConfig] = useState({
        rsystem: '1B-VKF',
        rfacility: '1DQ1-A - GEZ T2 Reprocessing T2 Reactions',
    })
    const fetchData = () => {
        Promise.all([postIndustry(['process_reactions'], { config: config })])
            .then((results) => {
                setData(results[0].data)
                setLoaded(true)
                setError(null)
            })
            .catch((error) => {
                setError(error)
            })
    }

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: '',
                width: 50,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
                            alt={params.row.itemName}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'typeName',
                headerName: 'Item',
                width: 400,
            },
            {
                field: 'groupName',
                headerName: 'Group',
                width: 200,
            },
            {
                field: 'real_cost',
                headerName: 'Cost',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'sell',
                headerName: '1DQ sell',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'local_volume',
                headerName: 'Volume',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'sell_export',
                headerName: 'Jita sell',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'export_volume',
                headerName: 'Volume',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'volume_price',
                headerName: 'ISK per m^3',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'run_price',
                headerName: 'ISK per run',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
        ],
        []
    )

    useEffect(() => {
        fetchData()
    }, [])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress />

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>Reactions</title>
            </Helmet>
            <div style={{ textAlign: 'center' }}>
                Cost: build price from jita buy, except blocks in 1DQ <br />
                Jita sell: value after removing shipping cost (I'm not assuming your broker fee rate) <br />
            </div>
            <FormControl>
                <InputLabel>Reacting Facility</InputLabel>
                <NativeSelect
                    inputProps={{
                        name: 'rfacility',
                    }}
                    onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                        setConfig((x) => ({ ...x, rfacility: e.target.value }))
                    }
                >
                    <option value={'1DQ1-A - GEZ T2 Reprocessing T2 Reactions'}>T2 Reactor</option>
                    <option value={'GEZ T1 Tatara'}>T1 Reactor</option>
                </NativeSelect>
            </FormControl>
            <FormControl>
                <InputLabel>Reacting System</InputLabel>
              <NativeSelect
                inputProps={{
                  name: 'rsystem',
                }}
                onChange={(e: HashChangeEvent<HTMLSelectElement>) =>
                  setConfig((x) => ({ ...x, rsystem: e.target.value }))
                }
              >
                <option value={'A4B-V5'}>A4B-V5</option>
                <option value={'B-S347'}>B-S347</option>
                <option value={'B9E-H6'}>B9E-H6</option>
                <option value={'Jita'}>Jita</option>
                <option value={'JK-Q77'}>JK-Q77</option>
                <option value={'SPBS-6'}>SPBS-6</option>
                <option value={'UALX-3'}>UALX-3</option>
                <option value={'Y19P-1'}>Y19P-1</option>
                <option value={'Y2-QUV'}>Y2-QUV</option>
              </NativeSelect>
            </FormControl>
          <FormControl>
            <Button variant="contained" onClick={fetchData}>
              Apply
            </Button>
          </FormControl>
          <ul style={{ textAlign: 'left' }}>
            <li>Reaction facility: {config.rfacility}</li>
            <li>Reaction system: {config.rsystem}</li>
          </ul>
          <DataGrid columns={columns} rows={data} rowHeight={25} autoHeight />
        </div>
    )
}

export default ReactionsProcess
