import { useEffect, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { Container, LinearProgress, Switch } from '@material-ui/core'
import { getSSOInfo, postSSO } from '../data'
import { Helmet } from 'react-helmet-async'

function Admin() {
    const [data, setData] = useState([])
    const [changeID, setChangeID] = useState(0)
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const columns = [
        {
            field: 'gice_id',
            headerName: 'ID',
            width: 80,
        },
        {
            field: 'name',
            headerName: 'Name',
            width: 180,
        },
        {
            field: 'last_seen',
            headerName: 'Last Seen',
            width: 250,
        },
        {
            field: '_1',
            headerName: 'Public',
            valueGetter: (params) => params.row.permissions.public,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    name={'Public'}
                    onChange={(e) => {
                        changePerm(params.row.gice_id, 'public', e.target.checked)
                    }}
                />
            ),
            width: 130,
        },
        {
            field: '_2',
            headerName: 'Industry',
            valueGetter: (params) => params.row.permissions.industry,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    name={'Industry'}
                    onChange={(e) => {
                        changePerm(params.row.gice_id, 'industry', e.target.checked)
                    }}
                />
            ),
            width: 130,
        },
        {
            field: '_3',
            headerName: 'IS',
            valueGetter: (params) => params.row.permissions.is,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    name={'IS'}
                    onChange={(e) => {
                        changePerm(params.row.gice_id, 'is', e.target.checked)
                    }}
                />
            ),
            width: 130,
        },
    ]

    const changePerm = (id, perm, state) => {
        postSSO(['admin', 'users', id], { permissions: { [perm]: state } })
            .then((result) => {
                setChangeID((x) => x + 1)
                return true
            })
            .catch(() => {
                return false
            })
    }

    useEffect(() => {
        const fetchData = () => {
            Promise.all([getSSOInfo(['admin', 'users'])])
                .then((results) => {
                    setData(results[0].data)
                    setLoaded(true)
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [changeID])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress />

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>Admin</title>
            </Helmet>
            <DataGrid columns={columns} rows={data} rowHeight={25} getRowId={(row) => row.gice_id} autoHeight />
        </div>
    )
}

export default Admin
