import { useEffect, useState } from 'react'
import { getIndustryInfo } from '../data'
import { Container, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
})

function EveItem({ item }) {
    const [data, setData] = useState({})
    const [error, setError] = useState(null)
    const classes = useStyles()

    useEffect(() => {
        const fetchData = () => {
            Promise.all([getIndustryInfo(['item', item])])
                .then((results) => {
                    setData(results[0].data)
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [item])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    return (
        <>
            {data.typeID !== undefined ? (
                <img src={`https://images.evetech.net/types/${data.typeID}/icon?size=64`} alt={data.typeName} />
            ) : null}
            <TableContainer>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableBody>
                        {Object.keys(data).map((key, idx) => (
                            <TableRow key={idx}>
                                <TableCell component="th" scope="row">
                                    {key}
                                </TableCell>
                                <TableCell>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: (data[key]?.toLocaleString() ?? '').replace('\n', '<br />'),
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default EveItem
