import { useEffect, useState, useMemo } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { getIndustryInfo } from '../data'
import { Container, LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

function OreValue() {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: '',
                width: 50,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
                            alt={params.row.name}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'name',
                headerName: 'Ore',
                width: 250,
            },
            {
                field: 'market',
                headerName: 'Market ISK / m^3',
                width: 250,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'net KF',
                headerName: 'Net ISK / m^3 (corp tax)',
                width: 250,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'net locust',
                headerName: 'Net ISK / m^3 (Locust tax)',
                width: 250,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'group',
                headerName: 'Group',
                width: 250,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
        ],
        []
    )

    useEffect(() => {
        const fetchData = () => {
            Promise.all([getIndustryInfo(['appraise_ore'])])
                .then((results) => {
                    setData(results[0].data)
                    setLoaded(true)
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress />

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>Ore Values</title>
            </Helmet>
            <DataGrid columns={columns} rows={data} rowHeight={25} autoHeight />
        </div>
    )
}

export default OreValue
