import { DataGrid } from '@material-ui/data-grid'
import { useEffect, useMemo, useState } from 'react'
import { postIndustry } from '../data'
import { Container, LinearProgress } from '@material-ui/core'

function IndustryList({ buildList, industryConfig }) {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const columns = useMemo(
        () => [
            {
                field: 'typeID',
                headerName: '',
                width: 50,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
                            alt={params.row.item}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'item',
                headerName: 'Item',
                width: 500,
                renderCell: (params) =>
                    params.row.invalid || !params.row.can_build ? (
                        <span style={{ color: 'red' }}>{params.value}</span>
                    ) : (
                        <span>{params.value}</span>
                    ),
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                width: 120,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'me',
                headerName: 'ME',
                width: 100,
                renderCell: (params) =>
                    params.row.invalid || !params.row.can_build
                        ? null
                        : params.row.bpT2
                        ? industryConfig.t2_me ?? '0'
                        : industryConfig.t1_me ?? '0',
            },
            {
                field: 'te',
                headerName: 'TE',
                width: 100,
                renderCell: (params) =>
                    params.row.invalid || !params.row.can_build
                        ? null
                        : params.row.bpT2
                        ? industryConfig.t2_te ?? '0'
                        : industryConfig.t1_te ?? '0',
            },
        ],
        [industryConfig]
    )

    useEffect(() => {
        const fetchData = () => {
            setData([])
            Promise.all([postIndustry(['prepare'], buildList)])
                .then((results) => {
                    setData(results[0].data)
                    setLoaded(true)
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [buildList])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.response.data ?? error.message}</p>
            </Container>
        )
    }

    return (
        <div style={{ width: '100%' }}>
            {loaded ? null : <LinearProgress />}
            <DataGrid
                columns={columns}
                rows={data}
                rowHeight={25}
                autoHeight
                getRowId={(row) => row.typeID ?? row.item}
            />
        </div>
    )
}

export default IndustryList
