import { Helmet } from 'react-helmet-async'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBlock: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

function About() {
    const classes = useStyles()
    return (
        <Grid container spacing={3}>
            <Helmet>
                <title>About</title>
            </Helmet>
            <Grid item xs={3} />
            <Grid item xs={6}>
                <Paper className={classes.paper}>
                    <p>Built by Chouk Root and Hari Lindberg</p>
                    <p>Hosted on personal resources</p>
                    <p>No guarantees, always double check!</p>
                </Paper>
                <Paper className={classes.paper}>
                    EVE Online and the EVE logo are the registered trademarks of CCP hf. All rights are reserved
                    worldwide. All other trademarks are the property of their respective owners. EVE Online, the EVE
                    logo, EVE and all associated logos and designs are the intellectual property of CCP hf. All artwork,
                    screenshots, characters, vehicles, storylines, world facts or other recognizable features of the
                    intellectual property relating to these trademarks are likewise the intellectual property of CCP hf.
                    CCP hf. has granted permission to this app to use EVE Online and all associated logos and designs
                    for promotional and information purposes on its website but does not endorse, and is not in any way
                    affiliated with this app. CCP is in no way responsible for the content on or functioning of this
                    website, nor can it be liable for any damage arising from the use of this website.
                </Paper>
            </Grid>
            <Grid item xs={3} />
        </Grid>
    )
}

export default About
