import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import AppsIcon from '@material-ui/icons/Apps'
import HelpIcon from '@material-ui/icons/Help'
import { Link as RouterLink } from 'react-router-dom'
import { Avatar, Link, Typography } from '@material-ui/core'
import { useRecoilValue } from 'recoil'
import { getIdentity } from '../data'

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}))

export default function NavBar() {
    const loginData = useRecoilValue(getIdentity)
    const classes = useStyles()

    return (
        <div className={classes.grow}>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        component={RouterLink}
                        to={'/index.html'}
                    >
                        <Avatar alt="Logo" src="/logo.png" />
                    </IconButton>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        component={RouterLink}
                        to={'/index.html'}
                    >
                        <AppsIcon />
                    </IconButton>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        component={RouterLink}
                        to={'/about.html'}
                    >
                        <HelpIcon />
                    </IconButton>
                    <div className={classes.grow} />
                    <Typography>{loginData.name}</Typography>&nbsp;
                    <Link href={`${process.env.REACT_APP_BASE_URL}sso/logout`} color={'secondary'}>
                        Logout
                    </Link>
                </Toolbar>
            </AppBar>
        </div>
    )
}
