import React from 'react'
import PropTypes from 'prop-types'

import { format } from 'd3-format'
import { timeFormat } from 'd3-time-format'

import { Chart, ChartCanvas } from 'react-stockcharts'
import { BarSeries, CandlestickSeries, LineSeries } from 'react-stockcharts/lib/series'
import { XAxis, YAxis } from 'react-stockcharts/lib/axes'
import { ema, wma, sma, tma } from 'react-stockcharts/lib/indicator'
import { OHLCTooltip, MovingAverageTooltip } from 'react-stockcharts/lib/tooltip'
import {
    CrossHairCursor,
    CurrentCoordinate,
    MouseCoordinateX,
    MouseCoordinateY,
} from 'react-stockcharts/lib/coordinates'

import { discontinuousTimeScaleProvider } from 'react-stockcharts/lib/scale'
import { fitWidth } from 'react-stockcharts/lib/helper'
import { last } from 'react-stockcharts/lib/utils'

class StockChart extends React.Component {
    render() {
        const { type, data: initialData, width, ratio } = this.props

        const sma20 = sma()
            .options({ windowSize: 20 })
            .skipUndefined(true)
            .merge((d, c) => {
                d.sma20 = c
            })
            .accessor((d) => d.sma20)
            .stroke('blue')

        const wma20 = wma()
            .options({ windowSize: 20 })
            .merge((d, c) => {
                d.wma20 = c
            })
            .accessor((d) => d.wma20)

        const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor((d) => d.date)
        const calculatedData = sma20(wma20(initialData))
        const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(calculatedData)

        const start = xAccessor(last(data))
        const end = xAccessor(data[Math.max(0, data.length - 100)])
        const xExtents = [start, end]

        return (
            <ChartCanvas
                height={600}
                ratio={ratio}
                width={width}
                margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
                type={type}
                seriesName="MSFT"
                data={data}
                xScale={xScale}
                xAccessor={xAccessor}
                displayXAccessor={displayXAccessor}
                xExtents={xExtents}
            >
                <Chart id={1} height={400} yExtents={(d) => [d.highest, d.lowest]}>
                    <YAxis axisAt="right" orient="right" ticks={5} />
                    <XAxis axisAt="bottom" orient="bottom" showTicks={false} />
                    <CandlestickSeries />
                    <LineSeries yAccessor={sma20.accessor()} stroke={sma20.stroke()} />
                    <LineSeries yAccessor={wma20.accessor()} stroke={wma20.stroke()} />
                    <CurrentCoordinate yAccessor={sma20.accessor()} fill={sma20.stroke()} />
                    <CurrentCoordinate yAccessor={wma20.accessor()} fill={wma20.stroke()} />
                    <MovingAverageTooltip
                        onClick={(e) => console.log(e)}
                        origin={[0, 20]}
                        options={[
                            {
                                yAccessor: sma20.accessor(),
                                type: 'SMA',
                                stroke: sma20.stroke(),
                                windowSize: sma20.options().windowSize,
                                echo: 'some echo here',
                            },
                            {
                                yAccessor: wma20.accessor(),
                                type: 'WMA',
                                stroke: wma20.stroke(),
                                windowSize: wma20.options().windowSize,
                                echo: 'some echo here',
                            },
                        ]}
                    />
                    <MouseCoordinateX at="bottom" orient="bottom" displayFormat={timeFormat('%Y-%m-%d')} />
                    <MouseCoordinateY at="left" orient="left" displayFormat={format('.4s')} />
                </Chart>
                <Chart id={2} origin={(w, h) => [0, h - 150]} height={150} yExtents={(d) => d.volume}>
                    <XAxis axisAt="bottom" orient="bottom" />
                    <YAxis axisAt="left" orient="left" ticks={5} tickFormat={format('.2s')} />
                    <BarSeries yAccessor={(d) => d.volume} fill={(d) => '#6BA583'} />
                </Chart>
                <CrossHairCursor />
            </ChartCanvas>
        )
    }
}
StockChart.propTypes = {
    data: PropTypes.array.isRequired,
    width: PropTypes.number.isRequired,
    ratio: PropTypes.number.isRequired,
    type: PropTypes.oneOf(['svg', 'hybrid']).isRequired,
}

StockChart.defaultProps = {
    type: 'hybrid',
    ratio: 1,
}
StockChart = fitWidth(StockChart)

export default StockChart
