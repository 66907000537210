import {useEffect, useMemo, useState} from 'react'
import {DataGrid} from '@material-ui/data-grid'
import {Card, CardHeader, Container, LinearProgress, Link} from '@material-ui/core'
import {Alert, AlertTitle} from '@material-ui/lab'
import {getSSOInfo} from '../data'
import {Helmet} from 'react-helmet-async'
import Character from './Fields/Character'
import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
    grid: {
        marginBottom: theme.spacing(2),
    },
    card: {
        padding: theme.spacing(2),
        textAlign: 'center',
    },
}))

function ESI() {
    const classes = useStyles()
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: 'ID',
                width: 120,
            },
            {
                field: 'name',
                headerName: 'Name',
                width: 180,
                renderCell: (params) => <Character charName={params.value} charID={params.row.id}/>,
            },
            {
                field: 'scopes',
                headerName: 'Scopes',
                width: 250,
                renderCell: (params) => {
                    if (params.value.length === 67) return <span style={{color: 'red'}}>FULL</span>
                    return <span>{params.value.length} scopes</span>
                },
            },
        ],
        []
    )

    useEffect(() => {
        const fetchData = () => {
            Promise.all([getSSOInfo(['esi', 'list'])])
                .then((results) => {
                    setData(results[0].data)
                    setLoaded(true)
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress/>

    return (
        <div style={{width: '100%'}}>
            <Helmet>
                <title>ESI</title>
            </Helmet>
            <Grid container spacing={3} className={classes.grid}>
                <Grid item xs={3}>
                    <Card className={classes.card}>
                        <CardHeader title={'Register with all scopes'}/>
                        <Link href={`${process.env.REACT_APP_BASE_URL}sso/registerfull`} color={'inherit'}>
                            <img src={'/eve-sso-login-black-small.png'} alt={'EVE SSO FULL'}/>
                        </Link>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.card}>
                        <CardHeader title={'Register with limited scopes'}/>
                        <Link href={`${process.env.REACT_APP_BASE_URL}sso/register`} color={'inherit'}>
                            <img src={'/eve-sso-login-black-small.png'} alt={'EVE SSO'}/>
                        </Link>
                        <br/>
                        (open window, check online, check location and fetch market from structures)
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card className={classes.card}>
                        <CardHeader title={'Register moon puller'}/>
                        <Link href={`${process.env.REACT_APP_BASE_URL}sso/registermoon`} color={'inherit'}>
                            <img src={'/eve-sso-login-black-small.png'} alt={'EVE SSO'}/>
                        </Link>
                        <br/>
                        (need corp roles <b>Station_Manager</b>, <b>Accountant</b>)
                    </Card>
                </Grid>
            </Grid>
            <DataGrid columns={columns} rows={data} rowHeight={25} autoHeight/>
        </div>
    )
}

export default function ESICharacters() {
    return (<Alert severity="warning">
        <AlertTitle>Maintenance</AlertTitle>
        This interface is disabled for maintenance
    </Alert>)
}

//export default ESI
