import { FormControl, Grid, Input, InputLabel, makeStyles, Paper, Tab, Tabs } from '@material-ui/core'
import { useState } from 'react'
import EveItem from './EveItem'
import PropTypes from 'prop-types'
import EveMarketItem from './EveMarketItem'
import { Helmet } from 'react-helmet-async'
import EveMarketStock from './EveMarketStock'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props
    const classes = useStyles()

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Paper className={classes.paper}>{children}</Paper>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function InfoHome() {
    const classes = useStyles()
    const [item, setItem] = useState('')
    const [tab, setTab] = useState(0)
    const handleChange = (event) => {
        setItem(event.target.value)
    }
    const changeTab = (event, newValue) => {
        setTab(newValue)
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <title>ItemDB</title>
            </Helmet>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Paper className={classes.paper}>
                        <FormControl>
                            <InputLabel htmlFor="item">Item ID or name</InputLabel>
                            <Input
                                id="item"
                                onBlur={handleChange}
                                onKeyUp={(e) => {
                                    if (e.code === 'Enter') handleChange(e)
                                }}
                            />
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <Tabs value={tab} onChange={changeTab} aria-label="info tabs">
                        <Tab label="Info" id={'tab-0'} aria-controls={'tabpanel-0'} />
                        {/*<Tab label="Industry" id={'tab-1'} aria-controls={'tabpanel-1'} />*/}
                        <Tab label="Market" id={'tab-1'} aria-controls={'tabpanel-1'} />
                        {/*<Tab label="Stock" id={'tab-2'} aria-controls={'tabpanel-2'} />*/}
                    </Tabs>
                    <TabPanel value={tab} index={0}>
                        {item !== '' ? <EveItem item={item} /> : null}
                    </TabPanel>
                    {/*<TabPanel value={tab} index={1}>*/}
                    {/*    {item !== '' ? <EveIndustry item={item} /> : null}*/}
                    {/*</TabPanel>*/}
                    <TabPanel value={tab} index={1}>
                        {item !== '' ? <EveMarketItem item={item} /> : null}
                    </TabPanel>
                    {/*<TabPanel value={tab} index={2}>*/}
                    {/*    {item !== '' ? <EveMarketStock item={item} /> : null}*/}
                    {/*</TabPanel>*/}
                </Grid>
            </Grid>
        </div>
    )
}

export default InfoHome
