import React, { useEffect, useMemo, useState } from 'react'
import { getIndustryInfo } from '../data'
import { Container, LinearProgress, Link } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Helmet } from 'react-helmet-async'
import ReactTable, { ReactTableDefaults } from 'react-table-6'
import EveMarketItem from './EveMarketItem'

function MarketOpportunities() {
    const [data, setData] = useState([])
    const [srcMarket, setSrcMarket] = useState(null)
    const [dstMarket, setDstMarket] = useState(null)
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const superiorFilter = (filter, row, _) => {
        const id = filter.pivotId || filter.id
        return row[id] > filter.value
    }

    const columns = useMemo(
        () => [
            {
                accessor: 'typeName',
                Header: 'typeName',
                width: 500,
                Cell: (params) => {
                    return (
                        <Link
                            href={`https://everef.net/type/${params.original.type_id}`}
                            target={'_blank'}
                            rel={'noreferrer'}
                            color={'inherit'}
                        >
                            {params.original.categoryID !== 91 ? (
                                <img
                                    src={`https://images.evetech.net/types/${params.original.type_id}/icon?size=32`}
                                    alt={params.value}
                                    width={25}
                                    height={25}
                                />
                            ) : (
                                <img
                                    src={`https://everef.net/img/Icons/UI/SKINIcons/280.png?size=32`}
                                    alt={params.value}
                                    width={25}
                                    height={25}
                                />
                            )}
                            {params.value}
                        </Link>
                    )
                },
            },
            {
                accessor: 'groupName',
                Header: 'Group',
                width: 250,
            },
            {
                accessor: 'categoryName',
                Header: 'Category',
                width: 250,
            },
            {
                accessor: 'src_price',
                Header: 'Source Price',
                width: 130,
                filterMethod: superiorFilter,
                Cell: (params) => params.value.toLocaleString(),
            },
            {
                accessor: 'src_volume',
                Header: 'Source Volume',
                width: 130,
                filterMethod: superiorFilter,
                Cell: (params) => params.value.toLocaleString(),
            },
            {
                accessor: 'dst_price',
                Header: 'Destination Price',
                width: 130,
                filterMethod: superiorFilter,
                Cell: (params) => params.value.toLocaleString(),
            },
            {
                accessor: 'dst_volume',
                Header: 'Destination Volume',
                width: 130,
                filterMethod: superiorFilter,
                Cell: (params) => params.value.toLocaleString(),
            },
            {
                accessor: 'margin',
                Header: 'Margin/u',
                width: 130,
                filterMethod: superiorFilter,
                Cell: (params) =>
                    params.value < 0 ? (
                        <span style={{ color: 'red' }}>{params.value.toLocaleString()}</span>
                    ) : (
                        <span>{params.value.toLocaleString()}</span>
                    ),
            },
            {
                accessor: 'markup',
                Header: 'Markup/u',
                width: 130,
                filterMethod: superiorFilter,
                Cell: (params) =>
                    params.value < 0 ? (
                        <span style={{ color: 'red' }}>{params.value.toLocaleString()} %</span>
                    ) : (
                        <span>{params.value.toLocaleString()} %</span>
                    ),
            },
        ],
        []
    )

    const stations = useMemo(
        () => [
            { id: 60003760, name: 'Jita' },
            { id: 60013945, name: 'Irmalin' },
            { id: 1028858195912, name: 'TTT Perimeter' },
            { id: 1030049082711, name: '1DQ1-A Keepstar' },
            { id: 1028081845045, name: '[HORDE] R10-GN Keepstar' },
            { id: 1031683557917, name: '[HORDE] BZ-BCK_Keepstar' },
            { id: 1030907008273, name: '[HORDE] UDVW-O_Keepstar' },
            { id: 1041043687391, name: 'Fade Stager'}
        ],
        []
    )

    useEffect(() => {
        const fetchData = () => {
            Promise.all([getIndustryInfo(['market_opportunities', srcMarket.id, dstMarket.id])])
                .then((results) => {
                    setData(results[0].data)
                    setLoaded(true)
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        if (srcMarket && dstMarket) {
            fetchData()
        } else {
            setError({ message: 'Select markets' })
        }
    }, [srcMarket, dstMarket])

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>Market opportunities</title>
            </Helmet>
            <p>
                This webpage will list all items from the source Market (lower sell order) that are cheaper than the
                lower sell order on the destination Market.
                <br />
                Note: Depending on your profile certains combinaisons may not be available.
            </p>

            <Autocomplete
                renderInput={(params) => <TextField {...params} label="Source Market" variant="outlined" />}
                style={{ width: 300 }}
                getOptionLabel={(option) => option.name}
                options={stations}
                value={srcMarket}
                onChange={(e, v) => {
                    setSrcMarket(v)
                }}
            />
            <Autocomplete
                renderInput={(params) => <TextField {...params} label="Destination Market" variant="outlined" />}
                style={{ width: 300 }}
                getOptionLabel={(option) => option.name}
                options={stations}
                value={dstMarket}
                onChange={(e, v) => {
                    setDstMarket(v)
                }}
            />
            {error ? (
                <Container>
                    <h3>Error</h3>
                    <p>{error.response?.data ?? error.message}</p>
                </Container>
            ) : (
                <>
                    {!loaded ? (
                        <LinearProgress />
                    ) : (
                        <ReactTable
                            style={{ width: '100%' }}
                            data={data}
                            columns={columns}
                            showPagination={data.length > ReactTableDefaults.defaultPageSize}
                            filterable={data.length > ReactTableDefaults.defaultPageSize}
                            SubComponent={(row) => {
                                return <EveMarketItem item={row.original.type_id} />
                            }}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export default MarketOpportunities
