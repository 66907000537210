import { has, reject } from 'lodash'

export function getModulesAndCargoList(eftText) {
  const list = eftText
    .trim()
    .substring(eftText.indexOf(']\n') + 1)
    .split(/\n/)
  return reject(list, (line) => line.trim() === '')
}

export function getItemsQuantity(eftText) {
  const rawItems = []
  try {
  // Remove non printable characters.
  eftText = eftText.replace(/[^\t\n\x20-\x7E]/g, '');
  getModulesAndCargoList(eftText).forEach((line) => {
    line = line.replace('/OFFLINE', '').trim()
    line = line.split('\t')
    let len = '1'
    if (line.length > 1)
    {
      len = line[1]
    }
    rawItems.push({ name: line[0], quantity: parseInt(len.replace(',','')) })
  })}
  catch (error)
  {
    console.error(error)
  }

  const fitItems = {}

  rawItems.forEach((item) => {
    if (item) {
      if (!has(fitItems, item.name)) {
        fitItems[item.name] = item.quantity
      } else {
        fitItems[item.name] += item.quantity
      }
    }
  })

  const output = []
  for (const key of Object.keys(fitItems)) {
    output.push({ item: key, quantity: fitItems[key] })
  }

  return output
}
