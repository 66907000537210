import { DataGrid } from '@material-ui/data-grid'
import { useMemo } from 'react'
import { Button } from '@material-ui/core'
import copy from 'clipboard-copy'

function IndustryBuild({ data, industryConfig }) {
    const copy_console = () => {
        copy(data.console)
    }
    const columns = useMemo(
        () => [
            {
                field: 'id',
                headerName: '',
                width: 50,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
                            alt={params.row.item}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'name',
                headerName: 'Item',
                width: 400,
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                width: 150,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'group',
                headerName: 'Group',
                width: 250,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'market_location',
                headerName: 'Market',
                width: 150,
            },
            {
                field: 'price',
                headerName: 'Price',
                width: 150,
                valueFormatter: (params) => params.value?.toLocaleString(),
            },
            {
                field: 'price_per_u',
                headerName: 'Price/u',
                width: 150,
                valueFormatter: (params) => params.value?.toLocaleString(),
            },
        ],
        []
    )

    const columns_bp = useMemo(
        () => [
            {
                field: 'id',
                headerName: '',
                width: 50,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/bpc?size=32`}
                            alt={params.row.item}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'name',
                headerName: 'Item',
                width: 400,
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                width: 150,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'time',
                headerName: 'Time',
                width: 150,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
        ],
        []
    )

    return (
        <div style={{ width: '100%' }}>
            <h4>Info</h4>
            <ul style={{ textAlign: 'left' }}>
                <li>Job cost: {data.job_cost ? data.job_cost.toLocaleString() : null}</li>
                <li>Input cost: {data.market_cost ? data.market_cost.toLocaleString() : null}</li>
                <li>Market stock value: {data.market_sell ? data.market_sell.toLocaleString() : null}</li>
            </ul>
            <h4>Input</h4>
            <DataGrid columns={columns} rows={data.input ?? []} rowHeight={25} autoHeight />
            <h4>Blueprints</h4>
            <DataGrid columns={columns_bp} rows={data.bp ?? []} rowHeight={25} autoHeight />
            <h4>Advanced reactions</h4>
            <DataGrid columns={columns_bp} rows={data.advanced ?? []} rowHeight={25} autoHeight />
            <h4>Intermediate reactions</h4>
            <DataGrid columns={columns_bp} rows={data.intermediate ?? []} rowHeight={25} autoHeight />
            <Button variant="contained" onClick={copy_console}>
                Copy output
            </Button>
        </div>
    )
}

export default IndustryBuild
