import { useEffect, useMemo, useState } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { getIndustryInfo } from '../data'
import { Container, LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

function PiProcess() {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const columns = useMemo(
        () => [
            {
                field: 'typeID',
                headerName: '',
                width: 50,
                valueGetter: (params) => params.row.product.typeID,
                renderCell: (params) =>
                    params.row.invalid ? null : (
                        <img
                            src={`https://images.evetech.net/types/${params.value}/icon?size=32`}
                            alt={params.row.product.typeName}
                            width={25}
                            height={25}
                        />
                    ),
            },
            {
                field: 'typeName',
                valueGetter: (params) => params.row.product.typeName,
                headerName: 'Product',
                width: 400,
            },
            {
                field: 'groupName',
                valueGetter: (params) => params.row.product.groupName,
                headerName: 'Group',
                width: 400,
            },
            {
                field: 'cost',
                headerName: 'Cost',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'sell',
                headerName: 'Sell',
                width: 200,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'margin',
                headerName: 'Margin',
                width: 200,
                renderCell: (params) =>
                    params.value < 0 ? (
                        <span style={{ color: 'red' }}>
                            {params.row.jita_estimate ? '*' : ''} {params.value.toLocaleString()}
                        </span>
                    ) : (
                        <span>
                            {params.row.jita_estimate ? '*' : ''} {params.value.toLocaleString()}
                        </span>
                    ),
            },
            {
                field: 'markup',
                headerName: 'Markup',
                width: 200,
                renderCell: (params) =>
                    params.value < 0 ? (
                        <span style={{ color: 'red' }}>
                            {params.row.jita_estimate ? '*' : ''} {params.value.toLocaleString()} %
                        </span>
                    ) : (
                        <span>
                            {params.row.jita_estimate ? '*' : ''} {params.value.toLocaleString()} %
                        </span>
                    ),
            },
        ],
        []
    )

    useEffect(() => {
        const fetchData = () => {
            Promise.all([getIndustryInfo(['process_pi'])])
                .then((results) => {
                    setData(results[0].data)
                    setLoaded(true)
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress />

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>PI Process</title>
            </Helmet>
            Cost and Sell are 1DQ buy prices, with 1% export / 0.5% import POCO tax. If margin is prefixed by *, a Jita
            estimate is used (no buy order in 1DQ)
            <DataGrid columns={columns} rows={data} rowHeight={25} autoHeight />
        </div>
    )
}

export default PiProcess
