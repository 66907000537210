import { useEffect, useState, useMemo } from 'react'
import { DataGrid } from '@material-ui/data-grid'
import { getIndustryInfo } from '../data'
import { Container, LinearProgress } from '@material-ui/core'
import { Helmet } from 'react-helmet-async'

function Moons() {
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const columns = useMemo(
        () => [
            {
                field: 'region',
                headerName: 'Regions',
                width: 130,
            },
            {
                field: 'system',
                headerName: 'System',
                width: 130,
            },
            {
                field: 'planet',
                headerName: 'Planet',
                width: 120,
            },
            {
                field: 'moon',
                headerName: 'Moon',
                width: 120,
            },
            {
                field: 'quality',
                headerName: 'Quality',
                width: 120,
                renderCell: (params) => {
                    const colors = {
                        64: ' cyan',
                        32: ' green',
                        16: ' yellow',
                        8: ' orange',
                        4: ' red',
                    }
                    return <span style={{ color: colors[params.value] }}>R{params.value}</span>
                },
            },
            {
                field: 'est_volume',
                headerName: 'Est. volume 30j',
                width: 180,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'est_value_1dq',
                headerName: '1DQ Est. value 30j',
                width: 180,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'est_value_jita',
                headerName: 'Jita Est. value 30j',
                width: 180,
                valueFormatter: (params) => params.value.toLocaleString(),
            },
            {
                field: 'ores',
                headerName: 'Ores',
                width: 800,
                renderCell: (params) =>
                    Object.keys(params.value).map((x) => {
                        const v = params.value[x]
                        const colors = {
                            Loparite: 'cyan',
                            Monazite: 'cyan',
                            Xenotime: 'cyan',
                            Ytterbite: 'cyan',
                            Carnotite: 'green',
                            Cinnabar: 'green',
                            Pollucite: 'green',
                            Zircon: 'green',
                            Chromite: 'yellow',
                            Otavite: 'yellow',
                            Sperrylite: 'yellow',
                            Vanadinite: 'yellow',
                            Cobaltite: 'orange',
                            Euxenite: 'orange',
                            Scheelite: 'orange',
                            Titanite: 'orange',
                            Bitumens: 'red',
                            Coesite: 'red',
                            Sylvite: 'red',
                            Zeolites: 'red',
                        }
                        return (
                            <span style={{ color: colors[x], paddingRight: 20 }}>
                                {x} : {v}
                            </span>
                        )
                    }),
            },
        ],
        []
    )

    useEffect(() => {
        const fetchData = () => {
            Promise.all([getIndustryInfo(['moons'])])
                .then((results) => {
                    setData(results[0].data)
                    setLoaded(true)
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress />

    return (
        <div style={{ width: '100%' }}>
            <Helmet>
                <title>Moons</title>
            </Helmet>
            <DataGrid columns={columns} rows={data} rowHeight={25} autoHeight />
        </div>
    )
}

export default Moons
