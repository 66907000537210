import {makeStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
} from '@material-ui/core'
import {getFracksInfo, getIndustryInfo} from '../data'
import {useEffect, useState} from 'react'
import Moment from 'react-moment'
import {Alert, AlertTitle} from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    card: {
        padding: theme.spacing(2),
    },
    cardtitle: {
        fontSize: '1rem',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}))

const moon_ore = {
    Loparite: {color: 'cyan', type: 'R64'},
    Monazite: {color: 'cyan', type: 'R64'},
    Xenotime: {color: 'cyan', type: 'R64'},
    Ytterbite: {color: 'cyan', type: 'R64'},
    Carnotite: {color: 'green', type: 'R32'},
    Cinnabar: {color: 'green', type: 'R32'},
    Pollucite: {color: 'green', type: 'R32'},
    Zircon: {color: 'green', type: 'R32'},
    Chromite: {color: 'yellow', type: 'R16'},
    Otavite: {color: 'yellow', type: 'R16'},
    Sperrylite: {color: 'yellow', type: 'R16'},
    Vanadinite: {color: 'yellow', type: 'R16'},
    Cobaltite: {color: 'orange', type: 'R8'},
    Euxenite: {color: 'orange', type: 'R8'},
    Scheelite: {color: 'orange', type: 'R8'},
    Titanite: {color: 'orange', type: 'R8'},
    Bitumens: {color: 'red', type: 'R4'},
    Coesite: {color: 'red', type: 'R4'},
    Sylvite: {color: 'red', type: 'R4'},
    Zeolites: {color: 'red', type: 'R4'},
}

function FrackItem({data, prices}) {
    const classes = useStyles()

    const remaining = data.total_volume - data.consumed_volume

    return (
        <Grid item xs>
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar
                            alt={'icon'}
                            src={`https://images.evetech.net/types/${data.structure.type_id}/icon?size=64`}
                        />
                    }
                    title={<h3>{data.structure.name}</h3>}
                />
                <CardContent>
                    {data.active ? (
                        <>
                            {remaining > 1000 ? (
                                <Alert severity="success">
                                    <AlertTitle>Active</AlertTitle>
                                    Frack time: {data.cur_frack_date} (<Moment fromNow>{data.cur_frack_date}</Moment>)
                                    <br/>
                                    Expire: {data.cur_frack_expiration} (
                                    <Moment fromNow>{data.cur_frack_expiration}</Moment>)
                                </Alert>
                            ) : (
                                <Alert severity="warning">
                                    <AlertTitle>Probably empty</AlertTitle>
                                    Frack time: {data.cur_frack_date} (<Moment fromNow>{data.cur_frack_date}</Moment>)
                                    <br/>
                                    Expire: {data.cur_frack_expiration} (
                                    <Moment fromNow>{data.cur_frack_expiration}</Moment>)
                                </Alert>
                            )}
                            {data.jackpot ? (
                                <Alert severity="info">
                                    <AlertTitle>JACKPOT</AlertTitle>
                                </Alert>
                            ) : (
                                <></>
                            )}
                            {data.cur_ore ? (
                                <List>
                                    {data.cur_ore.map((x, idx) => {
                                        const remaining_ore = x.extraction_volume - x.consumed_volume
                                        return (
                                            <ListItem key={idx}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={x.typeName}
                                                        src={`https://images.evetech.net/types/${x.typeID}/icon?size=64`}
                                                        className={classes.small}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <>
                                                            {x.typeName}{' '}
                                                            <span style={{color: moon_ore[x.typeName]['color']}}>
                                                                {moon_ore[x.typeName]['type']}
                                                            </span>{' '}
                                                            ({prices[x.typeName]} ISK / m<sup>3</sup>)
                                                        </>
                                                    }
                                                    secondary={
                                                        <>
                                                            {x.extraction_volume.toLocaleString()} m<sup>3</sup>
                                                            &nbsp;
                                                            {remaining_ore > 1000 ? (
                                                                <>
                                                                    (remaining: {remaining_ore.toLocaleString()} m
                                                                    <sup>3</sup>)
                                                                </>
                                                            ) : (
                                                                <strong>Probably empty</strong>
                                                            )}
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            ) : null}
                            <Divider/>
                        </>
                    ) : null}
                    {data.chunk_arrival_time ? (
                        <>
                            <h3>Next frack</h3>
                            Arrival: {data.chunk_arrival_time} (<Moment fromNow>{data.chunk_arrival_time}</Moment>)
                            <br/>
                            AutoFrack: {data.natural_decay_time} (<Moment fromNow>{data.natural_decay_time}</Moment>)
                            <br/>
                            {data.next_ore ? (
                                <List>
                                    {data.next_ore.map((x, idx) => (
                                        <ListItem key={idx}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={x.typeName}
                                                    src={`https://images.evetech.net/types/${x.typeID}/icon?size=64`}
                                                    className={classes.small}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        {x.typeName}{' '}
                                                        <span style={{color: moon_ore[x.typeName]['color']}}>
                                                            {moon_ore[x.typeName]['type']}
                                                        </span>
                                                    </>
                                                }
                                                secondary={
                                                    <>
                                                        {x.extraction_volume.toLocaleString()} m<sup>3</sup>
                                                    </>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ) : null}
                        </>
                    ) : (
                        <Alert severity="error">
                            <AlertTitle>Not extracting</AlertTitle>
                        </Alert>
                    )}
                </CardContent>
            </Card>
        </Grid>
    )
}

function Fracks({corp_id}) {
    const classes = useStyles()
    const [data, setData] = useState([])
    const [prices, setPrices] = useState({})
    const [error, setError] = useState(null)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        const fetchData = () => {
            Promise.all([getFracksInfo([corp_id])])
                .then((results) => {
                    setData(results[0].data)
                })
                .catch((error) => {
                    setError(error)
                })
            Promise.all([getIndustryInfo(['appraise_ore'])])
                .then((results) => {
                    let pr = {}
                    results[0].data.forEach((x) => (pr[x.name] = x.market))
                    setPrices(pr)
                    setLoaded(true)
                    setError(null)
                })
                .catch((error) => {
                    setError(error)
                })
        }
        fetchData()
    }, [corp_id])

    if (error) {
        return (
            <Container>
                <h3>Error</h3>
                <p>{error.message}</p>
            </Container>
        )
    }

    if (!loaded) return <LinearProgress/>

    return (
        <div className={classes.root}>
            <Grid item spacing={3}>
                <Paper className={classes.paper}>
                    <Alert severity="warning">
                        <AlertTitle>Maintenance</AlertTitle>
                        This interface might be temporarily unavailable for maintenance.
                    </Alert>
                    <p>Checkout out tax if applicable</p>
                    <p>
                        <a href={'http://kf.fyi/irs'}>HERE</a>
                    </p>
                </Paper>
            </Grid>
            <Grid item spacing={3}>
                <br/>
            </Grid>
            {[...Array(Math.ceil(data?.length / 3) ?? 0)].map((x, idx) => (
                <Grid container spacing={3} key={idx}>
                    {data[idx * 3] ? <FrackItem data={data[idx * 3]} prices={prices}/> : <Grid item xs/>}
                    {data[idx * 3 + 1] ? <FrackItem data={data[idx * 3 + 1]} prices={prices}/> : <Grid item xs/>}
                    {data[idx * 3 + 2] ? <FrackItem data={data[idx * 3 + 2]} prices={prices}/> : <Grid item xs/>}
                </Grid>
            ))}
        </div>
    )
}

export default Fracks
